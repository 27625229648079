// Override default variables before the import
$body-bg: #fff;
$spacer: 0;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


/***************************************************************************
                            MIXINS
***************************************************************************/
@mixin border-radius($top-sx, $top-dx, $bottom-dx, $bottom-sx) {
    -webkit-border-radius: $top-sx $top-dx $bottom-dx $bottom-sx;
       -moz-border-radius: $top-sx $top-dx $bottom-dx $bottom-sx;
        -ms-border-radius: $top-sx $top-dx $bottom-dx $bottom-sx;
            border-radius: $top-sx $top-dx $bottom-dx $bottom-sx;
}

.row {
    margin: 0;
}

.container { 
    max-width: 100%; 
    padding-right: 0;
    padding-left: 0;
}

/***************************************************************************
                            UTILS STYLES
***************************************************************************/
.noBordersElement{
    margin: 0;
    padding: 0;
}

/***************************************************************************
                            HEADER STYLES
***************************************************************************/

// SLIDER
.carousel {
    max-height: 80vh;
    overflow: hidden;
}

.App-header{
    justify-content: start;
    background-color: #fff;
    min-height: auto;
}
.headerWhiteBorder {
    height: 10px;
    background-color: #666;
    border: 0;
    margin:0;    
}

.socialLinksContainer{
    @extend .col-12;
    @extend .col-sm-2;
    @extend .col-md-2;
    @extend .col-lg-1;
    text-align: right;
    padding: 0.2vw 2.4vw;
    background-color: #666;
    border: 0;
    margin:0;
    height: 50px;

}
.table>tbody>tr>td,
.table>tbody>tr>th {
  border-top: none !important;
}

/***************************************************************************
                            NAVBAR
***************************************************************************/
.navbar-nav a {
    color:#666;
    font-size: 1.2rem;
    padding: 1vw 1vw;
}
.navbar-nav a:hover  {
    color: #ff8401;
    font-weight: bold;
}
.navbar-brand {
    position: absolute;
    z-index:10;
    left: 0;
    top: -80%;
}
.navbar-collapse {
    background-color: #fff;
}
.navbar-toggler {
    margin: 2vw 0;
}
img.border {
    border: 1px solid #ddd;
    padding: 5px;
}
.partnerImagesContainer {
    display: block;
    margin: auto;
    height: 20vh;
}
.logo-partner {
    margin:13px;
    float:left;
}

@media screen and (max-width: 574px) {
    
}
@media screen and (min-width: 1670px) {
    .logo img {
        margin-top: 1vw;
    }
}
/***************************************************************************
                            CAROUSEL
***************************************************************************/
.carousel-caption{
    width: 39.8vw;
    height: 40vw;
    background-color: #444;
    color: #ff8401;
    left: auto;
    right: 0;
    bottom: 0;
    font-size: 0.8em;
    text-align: left;
    padding-left: 2%;
}
@media screen and (max-width: 650px){
    .carousel-caption{
        width: 50vw;
    }
}

.carousel-caption>h3 {
    text-transform: uppercase;
    font-size: 2em;
}

/***************************************************************************
                            TITLE
***************************************************************************/
.title {
    text-transform: uppercase;
    padding: 1vw 2vw;
}
.title.servizi {
    background: 
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
      rgba(218, 121, 18, 0.603), 
      rgba(218, 199, 180, 0.45)
    ),
    url("/images/responsive/DisplaySiemens/DisplaySiemens.jpg_2600.jpg");
    background-position-y: 30%
}
.title.d3link {
    background:  
    linear-gradient(
        rgba(218, 121, 18, 0.603), 
        rgba(218, 199, 180, 0.45)
    ),
    url("/images/responsive/Rack/Rack.jpg_2600.jpg")
}
.title.contatti {
    background: 
    linear-gradient(
      rgba(218, 121, 18, 0.603), 
      rgba(218, 199, 180, 0.45)
    ),
    url("/images/responsive/DisplaySiemens/DisplaySiemens.jpg_2600.jpg");
    background-position-y: 50%
}
.title.lavora-con-noi {
    background: 
    linear-gradient(
        rgba(218, 121, 18, 0.603), 
        rgba(218, 199, 180, 0.45)
    ),
    url("/images/responsive/Rack/Rack.jpg_2600.jpg");
    background-position-y: 50%
}
.navbar-nav { 
    background: 
    linear-gradient(
        rgba(255, 255, 255, 0.603), 
        rgba(255, 255, 255, 0.45)
    ),
    url("/images/logo/diemme_logo_transparent_large.png");
    background-repeat: no-repeat;
    background-position: 40vw 10vw;
}
.title.errore {
    background: 
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
        rgba(218, 121, 18, 0.603), 
        rgba(218, 199, 180, 0.45)
    ),
    url("/images/full/Free-Download-Art-2560-x-1440-Image.jpg")
}
/***************************************************************************
                            FORM
***************************************************************************/
.formContainer{
    padding-bottom: 3vh;
}
.formSubmitContainer {
    text-align: center;
    padding-top: 2vw;
}
/***************************************************************************
                            OTHERS
***************************************************************************/
.footer {
    margin-top: 5vw;
    background-color: #444;
    color: #eee;
    text-align: left;
}
.footer h2 {
    color: #eee;
    margin-bottom: 1.5vw;
}
.footerElement{
    width: 100%;
    clear: both;
}
.footericon {
    width: 40px;
    float: left;
    font-size: 1.3rem;
    line-height: 1.5;
}
.footericontext {
    float: left;
}
.addressContainer{
    font-size: 1.3em;
    line-height: 1.5;
    padding: 2vw;
}
.fiscContainer {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    float: left;
    color: #bbb !important;
    background-color: #444;
}

@media screen and (max-width: 990px) {
    .mainConent {}
    .content-left-col{
        flex-direction: row;
        padding: 2%;
        text-align: left;
    }   
    .content-right-col{
        background-color: #fafaf8;
        flex-direction: row;
        margin-top: 5vw;
        width: 100%;
    }
    .content-right-col h2 {
        text-align: left;
        color: #666;
        padding: 4vw 2vw;
    }
    .content-right-col p {
        text-align: left;
        padding: 4vw 2vw;
    }

    .footer {}
    .addressContainer{
        width: 100vw;
        height: 42vh;
    }
    .mapContainer {
        display: none;
    }

    .carousel-caption>h3 {
        text-transform: uppercase;
        font-size: 1.5em;
    }
}

@media screen and (min-width: 991px) {
    .fakeImage{
        width: 500px;
        height: 300px;
        background-color: #999;
    }

    .side_image{
        width: 30vw;
        position: relative;
        bottom: 0;
        margin-bottom: 0;
    }
    .side_image_container {
        overflow: hidden;
        width: 30vw;
        margin: 2vh 4vw;
    }
    .side_image_caption {
        margin: 0 4vw;
        text-align: center;
        margin-bottom: 5vh;
    }
    .main_image_container {
        width: 60vw;
        margin: 2vh 0;
    }
    .main_image_caption {
        margin-bottom: 5vh;
    }
}
@media screen and (max-width: 990px) {
    .fakeImage{
        width: 500px;
        height: 300px;
        background-color: #999;
    }

    .side_image{
        width: 90vw;
        position: relative;
        bottom: 0;
        margin-bottom: 0;
    }
    .side_image_container {
        overflow: hidden;
        width: 90vw;
        margin: 2vh 4vw;
    }
    .side_image_caption {
        margin: 0 4vw;
        text-align: center;
        margin-bottom: 5vh;
    }
    .main_image_container {
        width: 90vw;
        margin: 2vh 0;
    }
    .main_image_caption {
        margin-bottom: 5vh;
    }
}

@media screen and (min-width: 991px) {
    .mainConent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .content-left-col{
        width: 60vw;
        padding: 2%;
        text-align: left;
    }   
    .content-right-col{
        background-color: #fafaf8;
        border-color: #ddd;
        border-width: 0 0 1px 1px;
        border-style: none none solid solid;
        width: 40vw;
        @include border-radius(0, 0, 0, 25px);
        padding: 2% 0;
    }
    .content-right-col h2, .content-right-col p{
        padding: 1vw;
    }

    .footer {
        margin-top: 5vw;
        background-color: #444;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 34vh;
    }
    .addressContainer{
        width: 34vw;
        float: left;
    }
    .mapContainer {
        width: 72vw;
        height: 34vh;
        background-color: #eee;
    }

    .carousel-caption>h3 {
        text-transform: uppercase;
        font-size: 2em;
    }
}

/***************************************************************************
                        CARD
***************************************************************************/
.card{
    margin-top:2vw;
}
.card-link{
    color: #ff8401;
}
.card-link:hover{
    color: #ff8401;
    font-weight: bold;
}

/***************************************************************************
                        GENERAL TEXT STYLES
***************************************************************************/
@import url('https://fonts.googleapis.com/css?family=Ropa+Sans&display=swap');

* {
    font-family: 'Ropa Sans', sans-serif;
}

h1 {
    color: #fff;
}
p, a, h2, .col-form-label, .form-check-label, .carousel-caption {
    font-family: 'Ropa Sans', sans-serif;
    color: #666;
}
a, h2, .carousel-caption, .carousel-caption p {
    color: #eee;
}
.carousel-caption svg{
    margin-right: 10px;
}
.col-form-label, .form-check-label {
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
}
.form-group {
    margin-top: 20px;
}
h2 {
    color: #ff8401;
}




.backtop {
    @extend .float-right;
    margin-right: 2vw;
}
.submitbutton{
    padding: 5vw 0;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #ff8401 !important;
    border: 1px solid #ff8401 !important;
}
.downloads .card-title {
    text-align: center;
}

.marchiContainer {
    width: 100%;
    margin-top: 2vh;
}
.marchiItem{
    width: 25%;
    height: 5vh;
    float: left;
    padding: 3%;
    text-align: center;
    vertical-align: baseline
}
.marchiItem:nth-of-type(odd){
    background-color: #f1f1f1
}